.image-container {
    display: flex; /* Use flexbox to align the images horizontally */
    justify-content: left; /* Center the images horizontally */
    align-items: center; /* Center the images vertically */
}
  
.image-container img {
    width: 200px; /* Set the width of the images */
    height: 200px; /* Set the height of the images */
    object-fit: cover; /* Scale the images to cover the entire container */
    margin: 10px; /* Add some margin between the images */
}